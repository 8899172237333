<template>
    <vs-dropdown
        class="cursor-pointer mb-4 mr-4 items-per-page-handler"
        vs-trigger-click
    >
        <div
            class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
        >
            <span class="mr-2"> {{ label }} - {{ selectedStaff }} </span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
        </div>
        <vs-dropdown-menu>
            <vs-dropdown-item @click="setUser(null)">
                <span>All</span>
            </vs-dropdown-item>
            <vs-dropdown-item
                v-for="(staff, index) in options"
                :key="index"
                @click="setUser(staff)"
            >
                <span v-if="staff.details && staff.details.full_name">{{
                    staff.details.full_name
                }}</span>
                <span v-else-if="staff.email">{{ staff.email }}</span>
            </vs-dropdown-item>
        </vs-dropdown-menu>
    </vs-dropdown>
</template>

<script>
import { http } from '@/services'

export default {
    name: 'StaffUserSelect',
    components: {},
    props: ['label'],
    mounted() {
        this.fetchUsers()
    },
    data() {
        return {
            options: [],
            selected: '',
        }
    },
    computed: {
        selectedStaff() {
            if (this.selected) {
                if (this.selected.details && this.selected.details.full_name)
                    return this.selected.details.full_name
                if (this.selected.email) return this.selected.email
            }
            return 'Filter'
        },
    },
    methods: {
        setUser(value) {
            if (value) this.$emit('selected', value.id)
            else this.$emit('selected', null)
            this.selected = value
        },
        fetchUsers() {
            http.get('users_staff_widget')
                .then((response) => {
                    this.options = response.data
                })
                .catch((error) => {
                    console.log(error)
                })
        },
    },
}
</script>
